@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.press {
  background-color: $primaryBackground;

  &__content {
    margin: auto;
    padding: 0.5rem;
    // max-width: 1020px;
    min-height: 80vh;
    position: relative;
    overflow: hidden;
  }

  &__content-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    backdrop-filter: blur(20px);
  }

  &__content-background-overlay {
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: 1;
  }

  &__slide-show-container {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 10px 10px 40px 20px rgba(0, 0, 0, 0.75);
    margin: 1rem auto;
    position: relative;
    z-index: 1;
    // height: 70vh;

    @include tablet {
      width: 80%;
    }
  }
}