@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.footer__container {
    background-color: $primaryBackground;
    display: flex;
    justify-content: center;
    padding: 1rem 0 2rem;
    width: 100%;
}

.footer-item {
    font-size: .75rem;
    color: $primaryText;

    @include tablet {
        font-size: 1rem;
    }
}