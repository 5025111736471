@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.portfolio {
  &__background {
    background-color: $primaryBackground;
    padding-top: 1rem;
    height: fit-content;

    @include tablet {
      min-height: 100vh;
    }

    @include desktop {
      background-color: $primaryBackground;
      padding-top: 2rem;
    }
  }

  &__container {
    // border: 1px solid green;
    max-width: 767px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    object-fit: contain;

    @include tablet {
      max-width: 1150px;
      margin: 0 auto;
    }

    @include desktop {
      max-width: 1280px;
      margin: 0 auto;
    }
  }
}