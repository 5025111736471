@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.publications {
  background-color: $primaryBackground;
  padding-top: 1rem;
  width: 100%;
  min-height: 100vh;

  &__content {
    margin: auto;
    max-width: 1280px;
  }

  &__page-flip-container {
    margin: auto;
    max-width: 1080px;
  }
}
