@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.portfolio-category-card {
    padding-bottom: 1rem;
    &__image {
        width: 90%;
        height: auto;
        position: relative;
        cursor: pointer;
        object-fit: contain;
        transition: opacity 0.5s;

        @include tablet {
            width: 15rem;
            margin: 0 auto;
        }

        @include desktop {
            width: 18rem;
            margin: 0 auto;
            flex-wrap: wrap;
            position: relative;
            cursor: pointer;
            justify-content: space-between;
        }

        &:hover,
        &--hide {
            opacity: 0;
        }

        &-hover {
            width: 90%;
            height: auto;
            position: absolute;
            object-fit: contain;

            @include tablet {
                width: 15rem;
            }

            @include desktop {
                width: 18rem;
                margin: 0 auto;
                flex-wrap: wrap;
                position: absolute;
                justify-content: space-between;
            }
        }
    }
}
