@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.nav-logo {
  padding-top: 1rem;
  width: 10rem;

  @include tablet {
    width: 15rem;
  }

  @include desktop {
    width: 25rem;
  }

  &__container {
    background-color: $primaryBackground;
    display: flex;
    justify-content: center;
  }
}

.nav-bar {
  &__background {
    background-color: $primaryBackground;
    width: 100%;
  }

  &__container {
    padding: 0 1rem;
    display: flex;
    justify-content: space-evenly;

    @include desktop {
      margin: 0 auto;
      max-width: 1280px;
    }
  }

  &__item {
    font-size: 0.6rem;
    margin: 0.5rem 0;

    @include tablet {
      font-size: 1rem;
    }
  }

  &__link {
    color: $primaryText;
    text-decoration: none;

    &:hover,
    &.active {
      color: $primaryText-Light;
    }
  }
}
