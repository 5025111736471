@use "../../styles/partials/mixins" as *;

.slide-show {
  padding: 1.5rem;

  @include tablet {
    padding: .75rem;
  }

  @include desktop {
    padding: 1rem;
  }

  &__slide {
    height: 100%;
  }

  &__slide-content {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 60vh;

    @include tablet {
      height: 80vh;
    }
  }
}