@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.contact {
    &__background {
        background-color: $primaryBackground;
        width: 100%;
        min-height: 100vh;
    }

    &__container {
        position: relative;
        padding: 1rem 0;
        width: 100%;
        display: flex;
        justify-content: center;

        @include desktop {
            margin: 0 auto;
            max-width: 1280px;
        }
    }

    &__image {
        padding: 0 1rem;
        width: 100%;

        @include desktop {
            padding: 0;
        }
    }

    &__form {
        position: absolute;
        left: 8%;
        top: 27%;

        @include tablet {
            left: 5%;
        }

        @include desktop {
            top: 30%;
            left: 3%;
        }

        &-label {
            font-size: .75rem;

            @include tablet {
                font-size: 1rem;
            }

            @include desktop {
                font-size: 1.25rem;
            }
        }

        &-input {
            font-size: .5rem;
            font-weight: 100;
            font-family: 'Times New Roman', Times, serif;
            border: none;
            margin-top: .25rem;
            padding-left: .5rem;
            width: 35vw;
            display: block;

            @include tablet {
                font-size: 1rem;
                margin-bottom: .75rem;
            }

            @include desktop {
                font-size: 1.25rem;
                width: 31rem;
            }
        }

        &-input-message {
            font-weight: 100;
            font-family: 'Times New Roman', Times, serif;
            resize: none;

            @include tablet {
                padding: 0 0 5rem .5rem;
            }

            @include desktop {
                text-overflow: wrap;
                padding-bottom: 10rem;
            }

        }

        &-button {
            font-size: .5rem;
            cursor: pointer;
            line-height: 1.5;
            color: white;
            background-color: #17a2b8;
            border: none;
            border-radius: 0.2rem;
            padding: 0.25rem 0.5rem;

            @include tablet {
                font-size: 0.875rem;
            }

            @include desktop {
                font-size: 1rem;
            }
        }
    }

    &__email {
        font-size: .5rem;
        position: absolute;
        top: 50%;
        left: 68%;

        @include tablet {
            font-size: 1rem;
        }

        @include desktop {
            left: 70%;
        }
    }
}