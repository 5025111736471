@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;

.page-flip {
  padding: 1rem 1rem;
  background-color: rgba(0, 0, 0, 0.25);

  &__page-flip-container {
    justify-content: center;

    @include tablet {
      display: flex;
    }
  }

  &__page {
    position: relative;
  }

  &__page-header {
    background-color: rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__page-content {
    display: flex;
  }

  &__page-image {
    width: 100%;
  }

  &__button-row {
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
    gap: 2rem;

    @include tablet {
    }
  }

  &__button {
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.5;
    color: white;
    background-color: #17a2b8;
    border: none;
    border-radius: 0.2rem;
    padding: 0.25rem 0.5rem;
  }
}
