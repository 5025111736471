@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.home {
  background-color: $primaryBackground;
  padding: 1rem 1rem 2rem;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  &__content {
    margin: auto;
    width: 100%;
    max-width: 1020px;
    min-height: 100vh;
  }

  @include tablet {
    padding: 1rem 5rem 2rem;
  }

  @include desktop {
    padding: 1rem 0;
  }

  &__hero {
    width: 100%;
    height: fit-content;

    @include desktop {
      max-width: 1280px;
    }
  }
}
