@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.about {
    &__background {
        background-color: $primaryBackground;
    }

    &__container {
        padding: 1rem 0 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;

        @include tablet {
            min-height: 100vh;
            flex-direction: row;
        }

        @include desktop {
            padding: 2rem 0;
            margin: 0 auto;
            max-width: 1280px;
        }
    }

    &__left {
        padding: 0 1rem;

        @include tablet {
            width: 50%;
        }
    }

    &__image {
        padding: 0 5rem;
        width: 100%;

        @include tablet {
            padding: 0 .5rem;
        }

        @include desktop {
            max-width: 35rem;
            padding-top: 1rem;
            margin: 0 auto;
            display: flex;
        }
    }

    &__right {
        padding: 1rem 1rem 0;

        @include tablet {
            padding: 1rem 2rem 0;
            width: 50%;
        }

        @include desktop {
            padding: 0 1rem 0;
        }
    }

    &__text {
        font-size: 1rem;
        color: $primaryText;
        text-align: justify;
        align-self: center;

        @include desktop {
            font-size: 1.5rem;
        }
    }
}