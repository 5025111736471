@use "../../styles/partials/colors" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/global" as *;

.gallery {
    &__background {
        background-color: $primaryBackground;
    }

    &__container {
        padding: 1rem 0 2rem;
        width: 100%;
        min-height: 100vh;

        @include tablet {}

        @include desktop {
            padding: 2rem 0;
            margin: 0 auto;
            max-width: 1280px;
        }
    }
}