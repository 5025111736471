@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-transition-image {
  position: relative;
  width: 100%;

  &__frame {
    background-image: url("../../assets/images/frames/frame_001.png");
    width: 100%;
    background-size: cover;
    aspect-ratio: 1.425;
  }

  &__image {
    width: 100%;
    opacity: 1;
    position: absolute;

    &--next {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      animation: 0.5s ease-in-out 0s 1 fadeIn forwards;
    }
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}
